<script setup lang="ts">import { computed as _computed } from 'vue';

import _DM_emitHelper from '/plugin-define-model/emit-helper';
const _DM_emit = defineEmits(["update:modelValue"]);
defineProps({
  "modelValue": null,
  "editing": { type: Boolean,  }
})



const currentVisibility = _computed(() =>
  statusVisibilities.find(v => v.value === __props.modelValue) || statusVisibilities[0],
)

const chooseVisibility = (visibility: string) => {
  _DM_emitHelper(_DM_emit, 'update:modelValue', visibility)
}
</script>

<template>
  <CommonTooltip placement="top" :content="editing ? $t(`visibility.${currentVisibility.value}`) : $t('tooltip.change_content_visibility')">
    <CommonDropdown placement="bottom">
      <slot :visibility="currentVisibility" />
      <template #popper>
        <CommonDropdownItem
          v-for="visibility in statusVisibilities"
          :key="visibility.value"
          :icon="visibility.icon"
          :text="$t(`visibility.${visibility.value}`)"
          :description="$t(`visibility.${visibility.value}_desc`)"
          :checked="visibility.value === modelValue"
          @click="chooseVisibility(visibility.value)"
        />
      </template>
    </CommonDropdown>
  </CommonTooltip>
</template>
